html {
  font-size: 62.5%;
}

@media (max-width: 997px) {
  body {
    margin: 0;
    font:
      300 11px/1.4 'Helvetica Neue',
      sans-serif;
    color: #444;
    background: #022c43;
    overflow: visible;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@media (min-width: 998px) {
  body {
    margin: 0;
    font:
      300 11px/1.4 'Helvetica Neue',
      sans-serif;
    color: #444;
    background: #022c43;
    overflow: hidden;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

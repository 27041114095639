@media (min-width: 998px) {
  .page {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .top-tags {
    top: 3%;

    &::after {
      content: '<html>';
      display: block;
      position: absolute;
      left: -15%;
      bottom: 80%;
    }
  }

  .bottom-tags {
    position: absolute;
    bottom: 3% !important;

    &::after {
      content: '</html>';
      display: block;
      position: absolute;
      left: -15%;
      top: 100%;
    }
  }
  .tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
  }

  .bottom-tag-html {
    margin-left: -20px;
  }

  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1.5s;
  }

  .about-page,
  .contact-page {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;

      h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: 10px;

        &::before {
          content: '<h1>';
          font-family: 'La Belle Aurore';
          font-size: 25px;
          position: absolute;
          margin-top: -20px;
          left: -10px;
          opacity: 0.6;
          line-height: 10px;
        }

        &::after {
          content: '</h1>';
          font-family: 'La Belle Aurore';
          font-size: 25px;
          position: absolute;
          line-height: 10px;
          margin-left: 20px;
          left: -30px;
          bottom: -30px;
          opacity: 0.6;
        }
      }

      h2 {
        font-style: italic;
        font-size: 25px;
        color: #fff;

        &:hover {
          animation: pulse 0.75s ease-in-out;
        }
      }

      p {
        color: white;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;
        font-size: large;

        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
          animation-delay: 1.2s;
        }
        &:nth-of-type(3) {
          animation-delay: 1.3s;
        }
      }
    }

    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }
}

@media (max-width: 997px) {
  .page {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  .top-tags {
    top: 4%;
    padding-left: 4%;

    &::after {
      content: '<html>';
      display: block;
      position: absolute;
      left: 1%;
      bottom: 100%;
    }
  }

  .bottom-tags {
    position: absolute;
    padding-left: 4%;

    &::after {
      content: '</html>';
      display: block;
      position: absolute;
      left: 1%;
      top: 100%;
    }
  }

  .tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 15px;
    font-family: 'La Belle Aurore';
  }

  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    max-height: fit-content;
    position: absolute;
    opacity: 0;
    top: 50px;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1.5s;
    display: flex;
    flex-direction: column;
  }
  .contact-page {
    .text-zone {
      position: absolute;
      width: 98%;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      max-height: 90%;
      align-content: center;
      left: 1%;
      display: flex;
      flex-direction: column;

      .sub-text {
        h1 {
          font-size: 53px;
          font-family: 'Coolvetica';
          color: #ffd700;
          font-weight: 400;
          margin-top: 0;
          position: relative;
          text-align: center;
          padding-top: 0px;
          padding-bottom: 0;
          margin-bottom: 0;

        }

        h2 {
          font-style: italic;
          font-size: 21px;
          color: #fff;
          text-align: center;
          margin-top: 0;
          margin-bottom: 0;
          display: none;
        }

        p {
          font-size: 15px;
          color: white;
          font-family: sans-serif;
          font-weight: 300;
          min-width: fit-content;
          animation: pulse 1s;
          text-align: center;
          display: none;

          }
        
      }
    }
  }

  .about-page {
    .text-zone {
      display: flex;
      flex-direction: column;
      width: 95%;
      max-width: 400px !important;
      height: 100%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
      align-items: center;
      justify-content: center;
      align-self: center;


      h1 {
        font-size: 45px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        text-align: center;
        padding-top: 90px;
        padding-bottom: 0;
        margin-bottom: 0;

        &::before {
          content: '<h1>';
          font-family: 'La belle Aurore';
          color: #ffd700;
          font-size: 15px;
          position: absolute;
          left: 8%;
          animation: fadeInAfter 1s 1.7s both;
          opacity: 0.6;
        }

        &::after {
          content: '<h1/>';
          font-family: 'La belle Aurore';
          color: #ffd700;
          font-size: 15px;
          position: absolute;
          margin-top: 15%;
          left: 8%;
          animation: fadeInAfter 1s 1.7s both;
        }
      }
    }

    h2 {
      font-style: italic;
      font-size: 21px;
      color: #fff;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;

      &:hover {
        animation: pulse 0.75s ease-in-out;
      }
    }

    p {
      font-size: 15px;
      color: white;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
      text-align: center;

      &:nth-of-type(1) {
        animation-delay: 1.5s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.6s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.7s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media (min-width: 998px) {
  .contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;

        overflow: hidden;
        display: block;
        position: relative;
        opacity: 0;
        -webkit-animation: fadeInUp 2s 2s;
                animation: fadeInUp 2s 2s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        clear: both;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input:focus {
      outline: none;
    }

    input[type='text'],
    input[type='email'] {
      width: 99%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      -webkit-transition: 5000s;
      -o-transition: 5000s;
      transition: 5000s;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      min-height: 150px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      resize: none;
    }

    .flat-button {
      color: #ffd700;
      background: 0 0;
      font: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      float: right;
      border-radius: 3px;
    }
  }

  .info-map {
    position: absolute;
    background-color: black;
    top: 50px;
    right: 30%;
    z-index: 99;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    -webkit-animation: fadeIn 1s 2.5s;
            animation: fadeIn 1s 2.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    

    span {
      font-style: 16px;
      display: block;
      padding-top: 20px;
      color: #ffd700;
    }
  }

  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    -webkit-animation: backInRight 1s 2s;
            animation: backInRight 1s 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
}
@media (max-width: 997px) {
  .contact-form {
    width: 100%;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    ul {
      padding: 0;
      margin: 0;
      

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;

        overflow: hidden;
        display: block;
        position: relative;
        opacity: 0;
        -webkit-animation: fadeInUp 2s 2s;
                animation: fadeInUp 2s 2s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        clear: both;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input:focus {
      outline: none;
    }

    input[type='text'],
    input[type='email'] {
      width: 99%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      -webkit-transition: 5000s;
      -o-transition: 5000s;
      transition: 5000s;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      min-height: 150px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      resize: none;
    }

    .flat-button {
      color: #ffd700;
      background: 0 0;
      font: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      margin-left: 5%;
      padding: 10px;
      text-align: center;
      border-radius: 3px;
    }
  }

  .info-map {
    position: absolute;
    background-color: black;
    top: 50px;
    right: 30%;
    z-index: 99;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    -webkit-animation: fadeIn 1s 2.5s;
            animation: fadeIn 1s 2.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    display: none;

    span {
      font-style: 16px;
      display: block;
      padding-top: 20px;
      color: #ffd700;
    }
  }

  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    -webkit-animation: backInRight 1s 2s;
            animation: backInRight 1s 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
}
@media (min-width: 998px) {
  .nav-bar {
    background: #181818;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    width: 70px;

    span {
      font-family: 'La belle Aurore';
      color: #aaa;
      font: bold;
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 25px;
    }

    .logo {
      display: block;
      padding: 8px 0;

      img {
        display: block;
        margin: 8px auto;
        width: 50px;
        height: auto;

        &.sub-logo {
          width: 50px;
        }
      }
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      top: 50%;
      margin-top: -120px;
      width: 100%;

      a {
        font-size: 30px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
        padding-top: 25px;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
            color: #ffd700;
          }
        }
        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          opacity: 0;
          transition: all 0.3s ease-out;
        }
      }

      a.home-link {
        &:after {
          content: 'HOME';
        }
      }

      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }

      a.portfolio-link {
        &:after {
          content: 'PORTFOLIO';
        }
      }

      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }

      a.active {
        svg {
          color: #ffd700;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 1.818rem;
      width: 100%;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 30px;
          line-height: 16px;

          &:hover svg {
            color: #ffd700;
          }
        }
      }
    }
  }
}

@media (max-width: 997px) {

.nav-bar {
      width: 100%;
      min-width: auto;
      height: 60px;
      position: sticky;
      z-index: 3;
      display: flex;
      flex-direction: row;
      background: #181818;
      top: 0;

      span {
        font-family: 'La belle Aurore';
        color: #aaa;
        font: bold;
        position: sticky;
        flex-direction: row;
        width: min-content;
        font-size: 0.01rem;
        text-decoration: none;
        pointer-events: none !important;
        display: none;
      }

      .logo {
        display: inline-flex;
        flex-direction: row;
        width: 30%;
        position: sticky;

        img {
          display: flex;
          flex-direction: row;
          width: 30px;
          height: auto;
          align-self: center;
          padding-left: 2vh;
          padding-right: 2vh;
          background-color: #333;
          border-radius: 0vh 2vh 2vh 0vh;
        }
      }

      nav {
        display: flex;
        flex-direction: row;
        width: 40%;
        justify-content: center;
        align-items: center;

        a {
          font-size: 27px;
          text-align: center;
          color: #4d4d4e;
          text-decoration: none;
          width: 33.33%;
        }

        a.active {
          svg {
            color: #ffd700;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: row;
        width: 30%;
        list-style: none;
        height: 100%;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        justify-content: right;
        

        li {
          font-size: 30px;
          display: flex;
          height: 100%;
          width: 100%;
          color: #4d4d4e;
          text-align: center;
          align-items: center;
          text-decoration: none;
          width: 33.3%;
        }
      }
    }
  }


@media (min-width: 998px) {
  @keyframes fadeInAfter {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }
  .home-page {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      min-height: 90%;
      z-index: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    h1 {
      color: #fff;
      font-size: 88px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;

      &::before {
        content: '<h1>';
        font-family: 'La belle Aurore';
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: 0.1rem;
        left: 15px;
        animation: fadeInAfter 1s 1.7s both;
        opacity: 0.6;
      }

      &::after {
        content: '<h1/>';
        font-family: 'La belle Aurore';
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: 9rem;
        left: 20px;
        animation: fadeInAfter 1s 1.7s both;
      }

      img {
        width: 8rem;
        display: inline;
        margin-bottom: -40px;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
        padding-left: 2rem;
      }
    }

    h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeInAfter 1s 1.7s both;
    }

    .flat-button {
      width: 35%;
      align-self: center;
      color: #ffd700;
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeIn 1s 1.8s backwards;
      white-space: nowrap;
      text-align: center;

      &:hover {
        background: #ffd700;
        font-size: 13px;
        font-weight: 700;
        color: #333;
      }
    }
  }
}

@media (max-width: 997px) {
  @keyframes fadeInAfter {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }
  .home-page {
    .text-zone {
      line-height: 7.4rem;
      position: absolute;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 99.9%;
      min-height: 90%;
      z-index: 1;
      align-items: center;
      padding-top: 10%;
    }

    h1 {
      color: #fff;
      font-size: 5.091rem;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
      align-content: center;

      &::before {
        content: '<h1>';
        font-family: 'La belle Aurore';
        color: #ffd700;
        font-size: 15px;
        position: absolute;
        margin-top: -11%;
        left: 8%;
        animation: fadeInAfter 1s 1.7s both;
        opacity: 0.6;
      }

      &::after {
        content: '<h1/>';
        font-family: 'La belle Aurore';
        color: #ffd700;
        font-size: 15px;
        position: absolute;
        margin-top: 10%;
        left: 10%;
        animation: fadeInAfter 1s 1.7s both;
      }

      img {
        width: 64px;
        display: inline;
        margin-bottom: -40px;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
        padding-left: 14px;
      }
    }

    h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeInAfter 1s 1.7s both;
      padding-top: 5%;
      padding-bottom: 5%;
    }

    .flat-button {
      color: #ffd700;
      font-size: 5.4vw;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 0px 6.4vw;
      border: 1px solid #ffd700;
      margin: 0;
      float: left;
      animation: fadeIn 1s 1.8s backwards;

      &:hover {
        background: #ffd700;
        font-size: 13px;
        font-weight: 700;
        color: #333;
      }
    }
  }
}
